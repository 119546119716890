
import ApiService from "@/core/services/ApiService"
import { defineComponent, onMounted, ref, watch } from "vue"
import { Edit } from "@element-plus/icons-vue"
import { useRoute, useRouter } from "vue-router"
import { DateFormatTypes, dateForm } from "@/core/helpers/dateformat"
import LicenseComponent from "@/components/general/definitions/account/license/license.vue"
import { License } from "@/core/models/License"
import { Account } from "@/core/models/Account"
import swalConfirm from "@/core/helpers/swal-confirm"

export default defineComponent({
  name: "license-list",
  components: {
    LicenseComponent,
  },
  props: ["add"],
  setup(props) {
    const route = useRoute()
    const router = useRouter()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const licenseList = ref<License[]>([])
    const newLicense = ref(false)
    var accountData = ref<Account>({} as Account)
    const isLoading = ref(true)
    const activeLicenseId = ref<string | undefined>()

    watch(
      () => route.params.license_id,
      newValue => {
        activeLicenseId.value = newValue as string
      }
    )

    watch(
      () => props.add,
      newValue => {
        newLicense.value = true == newValue
      }
    )

    async function getLicenseList() {
      const { data } = await ApiService.get("account/" + route.params.account_id + "/licenses")
      licenseList.value = data
      isLoading.value = false
    }

    const getAccount = async accountId => {
      const { data } = await ApiService.get("account/" + accountId)
      accountData.value = data
    }

    const removeLicense = async licenseId => {
      const confirmResult = await swalConfirm()
      if (confirmResult.isConfirmed) {
        await ApiService.get("license/remove/" + licenseId)
        getLicenseList()
      }
    }

    const refresh = () => {
      if (route.params.account_id) {
        getAccount(route.params.account_id)
      } else {
        accountData.value = {} as Account
        router.push({ name: "account-list" })
      }
    }

    const handleLicenseDrawerClose = () => {
      router.push({ name: "license-list" })
    }

    onMounted(async () => {
      refresh()
      activeLicenseId.value = route.params.licenseId as string
      newLicense.value = props.add
      getLicenseList()
    })

    return {
      licenseList,
      removeLicense,
      accountData,
      Edit,
      isLoading,
      dateForm,
      DateFormatTypes,
      getLicenseList,
      handleLicenseDrawerClose,
      newLicense,
      activeLicenseId,
    }
  },
})
