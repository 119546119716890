import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-header card-title fw-bolder" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LicenseComponent = _resolveComponent("LicenseComponent")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_card = _resolveComponent("el-card")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_LicenseComponent, {
      onClosed: _ctx.handleLicenseDrawerClose,
      onGetlist: _ctx.getLicenseList,
      add: _ctx.newLicense,
      id: _ctx.activeLicenseId,
      account_id: _ctx.$route.params.account_id
    }, null, 8, ["onClosed", "onGetlist", "add", "id", "account_id"]),
    _createVNode(_component_el_card, { class: "box-card" }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("h2", null, _toDisplayString(_ctx.accountData.title) + " - Lisans Listesi", 1),
          _createElementVNode("div", null, [
            _createVNode(_component_el_button, {
              size: "small",
              color: "#626aef",
              plain: "",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'account-list' })))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Hesap Listesi")
              ]),
              _: 1
            }),
            _createVNode(_component_el_button, {
              size: "small",
              color: "#626aef",
              plain: "",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push({ name: 'license-add' })))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Yeni Lisans Ekle")
              ]),
              _: 1
            })
          ])
        ])
      ]),
      default: _withCtx(() => [
        _withDirectives((_openBlock(), _createBlock(_component_el_table, {
          data: _ctx.licenseList,
          style: {"width":"100%"},
          size: "small"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              prop: "startDate",
              label: "Başlangıç Tarihi"
            }, {
              default: _withCtx((scope) => [
                _createTextVNode(_toDisplayString(_ctx.dateForm(_ctx.DateFormatTypes.TR_SHORT, scope.row.startDate)), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              prop: "endDate",
              label: "Bitiş Tarihi"
            }, {
              default: _withCtx((scope) => [
                _createTextVNode(_toDisplayString(_ctx.dateForm(_ctx.DateFormatTypes.TR_LONG, scope.row.endDate)), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              prop: "maxBusinessCount",
              label: "Maks. İşletme Sayısı"
            }),
            _createVNode(_component_el_table_column, {
              prop: "maxBranchCount",
              label: "Maks. Şube Sayısı"
            }),
            _createVNode(_component_el_table_column, {
              prop: "maxStudentCount",
              label: "Maks. Öğrenci Sayısı"
            }),
            _createVNode(_component_el_table_column, {
              label: "İşlemler",
              fixed: "right",
              width: "180",
              align: "center"
            }, {
              default: _withCtx((scope) => [
                _createVNode(_component_el_button, {
                  onClick: ($event: any) => (
              _ctx.$router.push({
                name: 'license-edit',
                params: { account_id: _ctx.$route.params.account_id, license_id: scope.row.id },
              })
            ),
                  size: "small",
                  icon: _ctx.Edit
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Düzenle")
                  ]),
                  _: 2
                }, 1032, ["onClick", "icon"]),
                _createVNode(_component_el_button, {
                  onClick: ($event: any) => (_ctx.removeLicense(scope.row.id)),
                  size: "small",
                  type: "danger",
                  prop: ""
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Sil")
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["data"])), [
          [_directive_loading, _ctx.isLoading]
        ])
      ]),
      _: 1
    })
  ], 64))
}