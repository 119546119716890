
import { License } from "@/core/models/License"
import ApiService from "@/core/services/ApiService"

import { computed, defineComponent, ref, toRefs, watch } from "vue"
import { useRouter } from "vue-router"
import { getRule, RuleTypes } from "@/core/helpers/rules"
import { ElForm } from "element-plus"

export default defineComponent({
  name: "license-component",
  components: {},
  props: ["add", "id", "closed", "account_id"],
  emits: ["closed", "getlist"],
  setup(props, { emit }) {
    const router = useRouter()
    const { add, id } = toRefs(props)

    const licenseData = ref<License>({} as License)
    type FormInstance = InstanceType<typeof ElForm>
    const ruleFormRef = ref<FormInstance>()

    const rules = ref({
      accountId: getRule(RuleTypes.TEXT50, "Hesap"),
      startDate: getRule(RuleTypes.DATE, "Lisans Başlangıç"),
      endDate: getRule(RuleTypes.DATE, "Lisans Bitiş"),
      maxBusinessCount: getRule(RuleTypes.NUMBER, "Maksimum İşletme"),
      maxBranchCount: getRule(RuleTypes.NUMBER, "Maksimum Şube"),
      maxStudentCount: getRule(RuleTypes.NUMBER, "Maksimum Öğrenci"),
    })

    const getLicense = async id => {
      const { data } = await ApiService.get("license/" + id)
      licenseData.value = data
    }

    watch(
      () => props.id,
      newValue => {
        if (newValue) {
          ruleFormRef.value?.resetFields()
          getLicense(newValue)
        }
      }
    )

    const showDrawer = computed(() => props.id != undefined || props.add == true)

    watch(
      () => props.add,
      newValue => {
        if (newValue) {
          ruleFormRef.value?.resetFields()
          licenseData.value = {} as License
        }
      }
    )

    async function addLicense() {
      const data = { ...licenseData.value, accountId: props.account_id }
      await ApiService.post("license/add", data)
      emit("getlist")
      router.push({ name: "license-list" })
    }
    async function updateLicense() {
      const data = {
        ...licenseData.value,
        id: props.id,
        accountId: props.account_id,
      }
      await ApiService.post("license/edit", data)
      emit("getlist")
      router.push({ name: "license-list" })
    }

    function addOrUpdate(formEl) {
      if (!formEl) return
      formEl.validate(valid => {
        if (valid) {
          if (props.add) {
            addLicense()
          } else {
            updateLicense()
          }
        }
      })
    }

    return {
      showDrawer,
      newLicense: add,
      activeLicenseId: id,
      handleClose: props.closed,
      licenseData,
      addOrUpdate,
      ruleFormRef,
      rules,
    }
  },
})
